import Hls from "hls.js";
import React, { useEffect, useRef } from "react";
function Player({
  activeSong,
  isPlaying,
  seekTime,
  repeat,
  onEnded,
  onTimeUpdate,
  onLoadedData,
  restartStatus,
  isLooped
}) {

  const playerRef = useRef(null);

  console.log("restart", restartStatus);
  useEffect(() => {
    const player = playerRef.current;
    const audioSrc = `${process.env.REACT_APP_BASE_URL}/api/${
      activeSong.album_id
    }/stream/${activeSong.id || activeSong.track_id}.m3u8`;
    /*  const audioSrc =
      "https://mymedia.myn.global/tempspace/unencfiles/output.m3u8";
    console.log("audioSec", audioSrc);
 */
    if (Hls.isSupported()) {
      var url = audioSrc;
      const hls = new Hls();
      console.log("from-android");
      player.addEventListener("timeupdate", function () {
        console.log("curent-tme", player.currentTime);
        console.log("duration", player.duration);
        if (player.duration && player.currentTime) {
          onLoadedData(player.duration);
          onTimeUpdate(player.currentTime);
        }
      });

      hls.loadSource(url);

      hls.attachMedia(player);

      hls.on(Hls.Events.MEDIA_ATTACHED, () => {
        hls.loadSource(url);

        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          player.play();
        });
        hls.on(Hls.Events.FRAG_PARSING_USERDATA, (_, data) => {
          const currentTime = data.startPts; // Do something with the current time, e.g., update a progress bar
          //console.log("Current Time:", currentTime);
        });
      });
    } else if (player.canPlayType("application/vnd.apple.mpegurl")) {
      var url_apple = `${process.env.REACT_APP_BASE_URL}/api/${
        activeSong.album_id
      }/stream/${activeSong.id || activeSong.track_id}.m3u8`;
      // var url_apple =
      //   "https://mymedia.myn.global/tempspace/unencfiles/output.m3u8";
      console.log("from-safari-browser");
      player.src = url_apple;

      player.addEventListener("timeupdate", function () {
        console.log("curent-tme", player.currentTime);
        console.log("duration", player.duration);
        if (player.duration && player.currentTime) {
          onLoadedData(player.duration);
          onTimeUpdate(player.currentTime);
        }
      });
      player.addEventListener("loadedmetadata", () => {
        player.play();
      });
    }
  }, [activeSong.track_id, activeSong.album_id, activeSong.id]);

  if (playerRef.current) {
    if (isPlaying) {
      playerRef.current.play();
    } else {
      playerRef.current.pause();
    }
  }
  useEffect(() => {
    playerRef.current.currentTime = 0;
  }, [restartStatus]);

  useEffect(() => {
    playerRef.current.currentTime = seekTime;
  }, [seekTime]);
  console.log(activeSong.id);

  return (
    <audio
      ref={playerRef}
      controls
      style={{ display: "none" }}
      onEnded={onEnded}
      loop={isLooped}
    />
  );
}

export default Player;
