import axios from "axios";

export const retrieveLatestAlbum = async () => {
  console.log("retrive-album");
  try {
    let config = {
      method: "POST",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/api/album/latest`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    const latestAlbumData = await axios(config);
    return latestAlbumData.data.records;
  } catch (err) {
    console.log(err);
  }
};

export const retrieveAlbum = async (data) => {
  console.log("retrive-album");
  try {
    const params = {
      method: "POST",
      baseURL: process.env.REACT_APP_BASE_URL,
      url: "/api/album/list",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    const albumData = await axios(params);
    return albumData.data.records;
  } catch (err) {
    console.log(err);
  }
};

export const createAlbum = async () => {
  try {
    const params = {
      method: "POST",
      baseURL: process.env.REACT_APP_BASE_URL,
      url: "/api/album/latest",
      headers: {
        "Content-Type": "application/json",
      },
    };

    const albumData = await axios(params);
    return albumData.data;
  } catch (err) {
    console.log(err);
  }
};

export const favouriteUsers = async (mynId, authtoken) => {
  try {
    const params = {
      method: "GET",
      baseURL: process.env.REACT_APP_BASE_URL,
      url: `api/user/${mynId}/favourites`,
      headers: {
        "Content-Type": "application/json",
        authtoken,
      },
    };

    const favourites = await axios(params);
    return favourites.data;
  } catch (err) {
    console.log(err);
  }
};
