import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  favouriteUsers,
  retrieveAlbum,
  retrieveLatestAlbum,
} from "../../server/album";
import {
  retrieveGenersList,
  retrievelanguageList,
} from "../../server/playList";

const initialState = {
  currentSongs: [],
  currentIndex: 0,
  isActive: false,
  isPlaying: false,
  activeSong: {},
  genreListId: "",
  currentAlbumShuffleStatus: false,
  cachedIndexes: [],
  loopType: 0,
  mynId: "",
  authToken: "",
  albums: [],
  languages: [],
  genre: [],
  favTracks: [],
  cachedIndexesIndex: -1,
  tempCachedIndexesIndex: -1,
  themeMode: "",
  latestAlbum: [],
  fromMobile: "",
};

export const getLanguagesAndGenre = createAsyncThunk(
  "player/getLanguagesAndGenre",
  async () => {
    console.log("present");
    const language = await retrievelanguageList();
    const genre = await retrieveGenersList();
    const [languagesData, genreData] = await Promise.allSettled([
      language,
      genre,
    ]);
    return [languagesData.value, genreData.value];
  }
);

export const getFavTracks = createAsyncThunk(
  "player/getFavTracks",
  async (_, { getState }) => {
    const { mynId, authToken } = getState().player;
    const { result } = await favouriteUsers(mynId, authToken);
    const tempFav = [];
    result.forEach((value) => {
      tempFav.push({ ...value, myTuneId: 120 });
    });

    return tempFav;
  }
);

export const fetchAlbum = createAsyncThunk("player/fetchAlbum", async () => {
  let data = {
    page: Math.floor(Math.random() * 10) + 1,
    limit: 20,
    filters: [],
  };

  return await retrieveAlbum(data);
});

export const getLatestAlbum = createAsyncThunk(
  "player/getLatestAlbum",
  async () => {
    return await retrieveLatestAlbum();
  }
);
const playerSlice = createSlice({
  name: "player",
  initialState,
  reducers: {
    setActiveSong: (state, action) => {
      console.log("state.payload", action.payload);
      if (action.payload.playlistSongs) {
        if (state.currentAlbumShuffleStatus) {
          state.cachedIndexes = [];
          state.cachedIndexesIndex = -1;
          state.tempCachedIndexesIndex = -1;
        }
        console.log("playlist-songs", action.payload.playlistSongs);
        state.activeSong = action.payload.playlistSongs[0];
        state.currentSongs = action.payload.playlistSongs;
        state.currentIndex = 0;
        state.isActive = true;
        return;
      }
      if (action.payload.searchedSong) {
        if (state.currentAlbumShuffleStatus) {
          state.cachedIndexes = [];
          state.cachedIndexesIndex = -1;
          state.tempCachedIndexesIndex = -1;
        }
        console.log("searched-song", action.payload.searchedSong);
        state.activeSong = action.payload.searchedSong;
        state.isActive = true;
        return;
      }

      if (action.payload.albumSongs) {
        if (state.currentAlbumShuffleStatus) {
          state.cachedIndexes = [];
          state.cachedIndexesIndex = -1;
          state.tempCachedIndexesIndex = -1;
        }
        state.activeSong = action.payload.albumSongs[0];

        state.currentSongs = action.payload.albumSongs;
        state.currentIndex = 0;
        state.isActive = true;
        return;
      }

      if (action.payload.mytunesSongs) {
        state.activeSong = action.payload.mytunesSongs[0];
        state.currentSongs = action.payload.mytunesSongs;
        state.currentIndex = 0;
        state.isActive = true;
        return;
      }

      state.activeSong = action.payload.data;

      console.log("activeSong", state.activeSong);
      state.currentSongs = action.payload.songs;

      state.currentIndex = action.payload.index;
      state.isActive = true;
    },

    clearSongPlayer: (state, action) => {
      state.currentIndex = -1;
      state.isPlaying = false;
      state.isActive = false;
      state.cachedIndexesIndex = -1;
      state.tempCachedIndexesIndex = -1;
      state.cachedIndexes = [];
    },

    nextSong: (state, action) => {
      console.log("action.payload", action.payload);
      console.log("current-songs", state.currentSongs[0]);

      console.log("current-song-length", state.currentSongs.length);

      if (state.currentSongs.length === 1) {
        return;
      }

      if (state.currentIndex === state.currentSongs.length - 1) {
        state.currentIndex = 0;
        state.activeSong = state.currentSongs[state.currentIndex];
        state.isActive = true;
        return;
      }
      if (state.currentAlbumShuffleStatus) {
        if (state.cachedIndexes.length === state.currentSongs.length) {
          state.cachedIndexes = [];
          console.log("cleared", state.cachedIndexes);
        }
        if (state.tempCachedIndexesIndex < state.cachedIndexesIndex) {
          state.activeSong =
            state.currentSongs[state.cachedIndexes[action.payload]];
          state.isActive = true;
          state.tempCachedIndexesIndex += 1;
          return;
        }
        let flag = true;
        let randomIndex;
        while (flag) {
          randomIndex = Math.floor(Math.random() * state.currentSongs.length);
          if (!state.cachedIndexes.includes(randomIndex)) {
            flag = false;
          }
          console.log("randomIndex", randomIndex);
          //state.cachedIndexes.push(randomIndex);
        }

        state.cachedIndexes.push(randomIndex);
        state.cachedIndexesIndex += 1;
        state.tempCachedIndexesIndex += 1;
        state.activeSong = state.currentSongs[randomIndex];
        state.currentIndex = randomIndex;
        state.isActive = true;
        return;
      }

      state.activeSong = state.currentSongs[action.payload];

      state.currentIndex = action.payload;
      state.isActive = true;
    },

    prevSong: (state, action) => {
      console.log("currentIndex", state.currentIndex);
      if (state.currentAlbumShuffleStatus) {
        if (state.tempCachedIndexesIndex === 0) {
          return;
        }

        state.activeSong =
          state.currentSongs[state.cachedIndexes[action.payload]];
        state.tempCachedIndexesIndex = action.payload;
        state.currentIndex = state.cachedIndexes[action.payload];
        state.isActive = true;
        return;
      }
      if (state.currentIndex === 0) {
        return;
      }
      state.activeSong = state.currentSongs[action.payload];
      state.currentIndex = action.payload;
      state.isActive = true;
    },

    playPause: (state, action) => {
      state.isPlaying = action.payload;
    },

    selectGenreListId: (state, action) => {
      state.genreListId = action.payload;
    },
    toggleAlbumShuffleStatus: (state, action) => {
      state.currentAlbumShuffleStatus = action.payload;
    },
    incrementLoopType: (state, action) => {
      console.log("payload", action.payload);
      if (action.payload !== undefined) {
        state.loopType = action.payload;
        return;
      }
      state.loopType += 1;
    },

    setQueryParams: (state, action) => {
      state.authToken = action.payload.authToken;
      state.mynId = action.payload.mynId;
      state.themeMode = action.payload.themeMode;
      state.fromMobile = action.payload.fromMobile;
    },

    setTracks: (state, action) => {
      state.tempTracks = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAlbum.fulfilled, (state, action) => {
      console.log(action);
      console.log("action", action.payload);
      state.albums = state.albums.concat(action.payload);
    });
    builder.addCase(getLanguagesAndGenre.fulfilled, (state, action) => {
      state.genre = state.genre.concat(action.payload[1]);
      state.languages = state.languages.concat(action.payload[0]);
    });

    builder.addCase(getFavTracks.fulfilled, (state, action) => {
      state.favTracks = action.payload;
    });

    builder.addCase(getLatestAlbum.fulfilled, (state, action) => {
      state.latestAlbum = action.payload;
    });
  },
});

export const {
  setActiveSong,
  nextSong,
  prevSong,
  playPause,
  selectGenreListId,
  clearSongPlayer,
  toggleAlbumShuffleStatus,
  incrementLoopType,
  setQueryParams,
  setTracks,
  setFavTracks,
} = playerSlice.actions;

export default playerSlice.reducer;
