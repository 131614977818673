import React, { useContext, useState } from "react";
import { AiFillStepBackward, AiFillStepForward } from "react-icons/ai";
import { BsFillPauseFill, BsFillPlayFill } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { PlayerContext } from "../../contexts/PlayerContext";
import { TrackContext } from "../../contexts/TrackContext";
import AnimatedText from "../MusicPlayer/PlayerTitleWithPowerPlay/AnimatedText/AnimatedText";
import {
  clearSongPlayer,
  nextSong,
  playPause,
  prevSong,
} from "../../redux/features/playerSlice";
import DurationTrack from "../MusicPlayer/DurationTrack/DurationTrack";
import playerImage from "./../../assets/music.png";
import {
  ImageWithDetails,
  MusicPlayerBarContainer,
  MusicPlayerBarControls,
  MusicPlayerBarDetails,
  MusicPlayerBarTrack,
  PauePlayButtonIcon,
  SingerName,
  SongName,
  SongWithSingerName,
} from "./MusicPlayerBar.styled";
function MusicPlayerBar() {
  const {
    activeSong,
    currentSongs,
    currentIndex,
    isActive,
    isPlaying,
    currentAlbumShuffleStatus,
    tempCachedIndexesIndex,
  } = useSelector((state) => state.player);

  const { isPlayerOpen, openPlayer, closePlayer } = useContext(PlayerContext);

  let activeSongTitle = activeSong.title || activeSong.track_name;
  let activeSongArtistName =
    activeSong.artist ||
    activeSong.track_artist ||
    activeSong.artists.singers[0];

  const {
    duration,
    setTrackDuration,
    appTime,
    seekTime,
    setTrackAppTime,
    setTrackSeekTime,
  } = useContext(TrackContext);
  const dispatch = useDispatch();

  const [imageLoadError, setImageLoadError] = useState(false);
  const handleImageError = () => {
    setImageLoadError(true);
  };

  //console.log("active song-data", activeSong.data);

  function onPlayPauseControl() {
    if (isPlaying) {
      dispatch(playPause(false));
    } else {
      dispatch(playPause(true));
      closePlayer();
    }
  }

  function handlePlayerBarClose() {
    console.log("clicked");
    dispatch(clearSongPlayer());
    setTrackDuration(0);
    setTrackAppTime(0);
    setTrackSeekTime(0);
  }

  function handleForwardSongPlay() {
    console.log("activeSong", activeSong);

    if (currentAlbumShuffleStatus) {
      dispatch(nextSong(tempCachedIndexesIndex + 1));
      dispatch(playPause(true));
      return;
    }
    dispatch(nextSong(currentIndex + 1));
    dispatch(playPause(true));

    console.log("activeSong", activeSong);
  }

  function handleBackwardSongPlay() {
    if (currentAlbumShuffleStatus) {
      dispatch(prevSong(tempCachedIndexesIndex - 1));
      dispatch(playPause(true));
      return;
    }
    if (currentIndex === 0) {
      return;
    }
    dispatch(prevSong(currentIndex - 1));
    dispatch(playPause(true));
  }

  return (
    <MusicPlayerBarContainer>
      <MusicPlayerBarDetails>
        <ImageWithDetails onClick={() => openPlayer()}>
          <img
            src={
              imageLoadError
                ? playerImage
                : activeSong?.thumbnail ||
                  activeSong?.album_thumbnail ||
                  `${process.env.REACT_APP_IMAGE_URL}/audioplayer/images/${activeSong.album_id}/${activeSong.album_id}.jpg`
            }
            alt="img"
            onError={handleImageError}
          />
          <SongWithSingerName>
            <SongName>
              {/* {activeSong.title?.slice(0, 17) ||
                activeSong.track_name.slice(0, 17)}
              ... */}
              {activeSongTitle.length > 15 ? (
                <AnimatedText trackTitle={activeSongTitle} />
              ) : (
                activeSongTitle
              )}
            </SongName>

            <SingerName>
              {/* {activeSong.artist ||
                activeSong.track_artist ||
                activeSong.artists.singers[0]}... */}
              {activeSongArtistName.length > 20 ? (
                // <marquee>{activeSongArtistName}</marquee>
                <AnimatedText trackTitle={activeSongArtistName} />
              ) : (
                activeSongArtistName
              )}
            </SingerName>
          </SongWithSingerName>
        </ImageWithDetails>

        <MusicPlayerBarControls>
          {/* <AiFillStepBackward
            size={30}
            onClick={() => handleBackwardSongPlay()}
          /> */}
          <PauePlayButtonIcon onClick={() => onPlayPauseControl()}>
            {isPlaying ? (
              <BsFillPauseFill size={40} />
            ) : (
              <BsFillPlayFill size={40} />
            )}
          </PauePlayButtonIcon>
          <AiFillStepForward
            size={30}
            onClick={() => handleForwardSongPlay()}
          />
        </MusicPlayerBarControls>
        {/* <RxCross2
          size={18}
          style={{ color: "#B1B4BF", marginRight: "5px" }}
          onClick={() => handlePlayerBarClose()}
        /> */}
        <MusicPlayerBarTrack>
          <DurationTrack
            value={appTime}
            min="0"
            max={duration}
            onInput={(event) => setTrackSeekTime(event.target.value)}
            setSeekTime={setTrackSeekTime}
            appTime={appTime}
            onBar={true}
            barHeight={"2px"}
          />
        </MusicPlayerBarTrack>
      </MusicPlayerBarDetails>
    </MusicPlayerBarContainer>
  );
}

/* value={appTime}
            min="0"
            max={duration}
            onInput={(event) => setSeekTime(event.target.value)}
            setSeekTime={setSeekTime}
            appTime={appTime} */

export default MusicPlayerBar;
