import React, { useContext, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { PlayerContext } from "../../contexts/PlayerContext";
import { TrackContext } from "../../contexts/TrackContext";
import { nextSong, playPause } from "../../redux/features/playerSlice";
import DurationTrack from "./DurationTrack/DurationTrack";
import {
  ControlsAndTrack,
  MusicBannerWithControlsAndTrack,
  MusicPlayerContainer,
  MusicTrackWithControls,
  PlayerTopNav,
} from "./MusicPlayer.styled";
import MusicPlayerBanner from "./MusicPlayerBanner/MusicPlayerBanner";
import Player from "./Player/Player";
import PlayerControls from "./PlayerControls/PlayerControls";
import PlayerTitleWithPowerPlay from "./PlayerTitleWithPowerPlay/PlayerTitleWithPowerPlay";

function MusicPlayer({ isOpened, onBar, notify }) {
  const [isRestart, setIsRestart] = useState(false);
  const [isLooped, setIsLooped] = useState(false);
  const dispatch = useDispatch();
  const {
    activeSong,
    currentSongs,
    currentIndex,
    isActive,
    isPlaying,
    themeMode,
  } = useSelector((state) => state.player);
  // const themeMode = 'light'
  // eslint-disable-next-line no-unused-expressions

  const { isPlayerOpen, openPlayer, closePlayer } = useContext(PlayerContext);
  const {
    duration,
    setTrackDuration,
    appTime,
    seekTime,
    setTrackAppTime,
    setTrackSeekTime,
  } = useContext(TrackContext);
  console.log("isPlayed", isOpened);

  // const [duration, setDuration] = useState(0);
  //const [seekTime, setSeekTime] = useState(0);
  //const [appTime, setAppTime] = useState(0);

  function onPlayPauseControl() {
    if (isPlaying) {
      dispatch(playPause(false));
    } else {
      dispatch(playPause(true));
    }
  }

  function handlePlayerClose() {
    closePlayer();
  }

  /* const durationTrack = (<>
    <DurationTrack
      value={appTime}
      min="0"
      max={duration}
      onInput={(event) => setSeekTime(event.target.value)}
      setSeekTime={setSeekTime}
      appTime={appTime}
      onBar={onBar}
    />
    <Player
      activeSong={activeSong}
      isPlaying={isPlaying}
      seekTime={seekTime}
      //repeat={repeat}
      currentIndex={currentIndex}
      // onEnded={handleNextSong}
      onTimeUpdate={(event) => setAppTime(event.target.currentTime)}
      onLoadedData={(event) => setDuration(event.target.duration)}
    />
  </>)

  if (onBar) {
    return durationTrack;
  } */
  //const navigate = useNavigate();

  function handleNextSong() {
    setTimeout(() => {
      dispatch(nextSong(currentIndex + 1));
      dispatch(playPause(true));
    }, 130);
  }

  function handleRestart() {
    setIsRestart((prev) => !prev);
  }

  return (
    <MusicPlayerContainer isOpened={isOpened} theme={themeMode}>
      <PlayerTopNav>
        <IoIosArrowBack
          color={themeMode === "light" && "#5E3838"}
          size={24}
          onClick={() => handlePlayerClose()}
        />
        {/* <SlOptions /> */}
      </PlayerTopNav>

      <MusicBannerWithControlsAndTrack>
        <MusicPlayerBanner
          musicBanner={
            activeSong.thumbnail ||
            activeSong.album_thumbnail ||
            `${process.env.REACT_APP_IMAGE_URL}/audioplayer/images/${activeSong.album_id}/${activeSong.album_id}.jpg`
          }
        />
        <ControlsAndTrack>
          <PlayerTitleWithPowerPlay
            track_id={activeSong.track_id || activeSong.id}
            notify={notify}
          />
          <DurationTrack
            value={appTime}
            min="0"
            max={duration}
            onInput={(event) => setTrackSeekTime(event.target.value)}
            setSeekTime={setTrackSeekTime}
            appTime={appTime}
            onBar={onBar}
            barHeight={"5px"}
          />
          <Player
            activeSong={activeSong}
            isPlaying={isPlaying}
            seekTime={seekTime}
            //repeat={repeat}
            currentIndex={currentIndex}
            onEnded={handleNextSong}
            onTimeUpdate={(time) => setTrackAppTime(time)}
            onLoadedData={(duration) =>
              //console.log("duration", event.target.duration)
              setTrackDuration(duration)
            }
            restartStatus={isRestart}
            isLooped={isLooped}
          />

          <MusicTrackWithControls>
            <PlayerControls
              onPlayPauseControl={() => onPlayPauseControl()}
              restartSong={() => handleRestart()}
              setIsLooped={setIsLooped}
              isLooped={isLooped}
            />
          </MusicTrackWithControls>
        </ControlsAndTrack>
      </MusicBannerWithControlsAndTrack>
    </MusicPlayerContainer>
  );
}

export default MusicPlayer;
